<template lang="pug">
  div
    //- title panel
    v-container(
      fluid
      fill-height
    ).pa-0.ma-0.titlePanel
      v-layout(:class="$isMediumAndDown ? 'bgMobile' : 'bg'")
        v-row(
          no-gutters
          :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
        )
          v-col(
            cols="12"
            sm="12"
            v-if="$isMediumAndDown"
          ).mt-7
            h2.primary--text.fontsize-18 Would you like to be a Superhero?
            h1(:class="$isMobileXSmall ? 'fontsize-28' : 'fontsize-32'").font-weight-black.accent--text.mb-0 The sole meaning
              br
              | of life is to serve
              br
              | humanity.
            span.fontsize-18.secondary--text ~ Leo Tolstoy
          v-col(
            cols="12"
            sm="12"
            v-else
          ).mt-80
            h2.primary--text.fontsize-18 Would you like to be a Superhero?
            h1.fontsize-48.font-weight-black.accent--text.mb-0 The sole meaning
              br
              | of life is to serve
              br
              | humanity.
            span.fontsize-18.secondary--text ~ Leo Tolstoy

    //- detail panel
    v-container(fluid).px-0.pt-12.pb-0.fontsize-20
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
      ).panelLarge.text-center.mt-5
        v-col(:class="$isMediumAndDown ? '' : 'contentPanelLarge'").accent--text.text-justify
          p(
            v-for="(text, i) in textBlock1"
            :key="i"
          ) {{ text }}
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge contentPanelLarge'"
        justify="center"
      ).panelLarge.text-center.mt-4.mb-10
        v-card.pa-2
          v-img(
            src="@/assets/mmi-volunteers-image-banner-paulette.png"
            alt="Paulette with scholars"
          )
      v-row(
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
      ).panelLarge.text-center.mt-5
        v-col(:class="$isMediumAndDown ? '' : 'contentPanelLarge'").accent--text.text-justify
          template(v-for="(text, i) in textBlock2")
            p(
              v-if="i !== 1"
              :key="i" 
              :class="i === 3 ? 'mb-8 font-weight-black' : ''"
            ) {{ text }}
            p(
              v-else
              v-html="text"
              :key="i"
            )
      v-row(
        justify="center"
        no-gutters
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
      )
        v-col(:class="$isMediumAndDown ? '' : 'contentPanelLarge'")
          v-row.mx-0
            v-col(
              cols="12"
              sm="5"
            ).px-0
              v-col(
                v-for="(position, i) in positions"
                :key="i"
                cols="12"
              ).px-0
                v-row
                  v-col(
                    cols="12"
                    sm="3"
                  ).text-center.pa-0
                    v-avatar(size="75")
                      img(
                        :src="position.img"
                        :alt="position.alt"
                      )
                  v-col(
                    cols="12"
                    sm="9"
                    align-self="center"
                    :class="$isMobileXSmall ? 'text-center' : 'text-left'"
                  )
                    p(:class="!!position.text2 ? 'mb-0' : 'mb-4'") {{ position.text }}
                    span(v-if="!!position.text2").fontsize-14 {{ position.text2 }}
            v-col(
              cols="12"
              sm="7"
            ).text-center.px-0.py-4
              a(
                @click.stop="handleJoinLink"
                title="Mighty Minds Be A Volunteer"
              )
                v-card
                  v-img(
                    src="@/assets/mmi-volunteers-web-banner.png"
                    alt="Be a volunteer"
                  )
      v-row(
        no-gutters
        justify="center"
        :class="$isMediumAndDown ? 'panelMobile' : 'panelLarge'"
      ).text-center.mt-5.pb-150
        v-btn(
          @click.stop="handleJoinBtn"
          x-large
          color="primary"
          title="Mighty Minds Join Us Now"
        ).mt-5.fontsize-18.text-none Join Us Now
</template>

<script>
import banner from '@/assets/mmi-volunteers-web-banner.png';
import { POSITIONS, TEXT_BLOCK_1, TEXT_BLOCK_2 } from './constants';

export default {
  data () {
    return {
      positions: POSITIONS,
      textBlock1: TEXT_BLOCK_1,
      textBlock2: TEXT_BLOCK_2,
      banner
    };
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  methods: {
    open () {
      window.open('https://mmvolunteers.typeform.com/to/pzd0DI', '_blank');
    },
    handleJoinBtn () {
      this.$ga.event({
        eventCategory: 'button',
        eventAction: 'click-volunteer-join-btn',
        eventLabel: 'volunteer-join-btn'
      });
      this.open();
    },
    handleJoinLink () {
      this.$ga.event({
        eventCategory: 'link',
        eventAction: 'click-volunteer-join-link',
        eventLabel: 'volunteer-join-link'
      });
      this.open();
    }
  }
};
</script>

<style scoped>
.bg {
  background-image: url('../../assets/mmi-volunteer-usp-hero-image-helping-hand.png');
  background-repeat: no-repeat;
  background-position: center bottom;
}
.bgMobile {
  background-image: url('../../assets/mmi-hands.png');
  background-repeat: no-repeat;
  background-position: center bottom;
}
.mt-80 {
  margin-top: 80px;
}
.pb-150 {
  padding-bottom: 150px;
}
.contentPanelLarge {
  margin-left: 100px;
  margin-right: 100px;
}
</style>
