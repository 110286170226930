export const POSITIONS = Object.freeze([
  {
    img: require('@/assets/mmi-volunteers-icon-developer.png'),
    alt: 'Frontend developers',
    text: '✓ Frontend Developers',
    text2: '(knowledgeable in Javascript)'
  },
  {
    img: require('@/assets/mmi-volunteers-icon-designer.png'),
    alt: 'Graphic artists',
    text: '✓ Graphic Artists'
  },
  {
    img: require('@/assets/mmi-volunteers-icon-social-media.png'),
    alt: 'Social media manager',
    text: '✓ Social Media Manager'
  },
  {
    img: require('@/assets/mmi-volunteers-icon-writer.png'),
    alt: 'Content writers',
    text: '✓ Content Writers'
  },
  {
    img: require('@/assets/mmi-volunteers-icon-legal-advisor.png'),
    alt: 'Legal advisor',
    text: '✓ Legal Advisor'
  }
]);

export const TEXT_BLOCK_1 = Object.freeze([
  'Eleanor is a 15-year old girl from the Philippines. Her parents separated when she was young and she stayed with her father since then. Her mom works in the city as a household helper with a fixed salary of $60 per month. Meanwhile, her dad would accept carpentry projects that pay him $10 a day but would take any other odd jobs as necessary.',
  'One hot afternoon, Eleanor was crying for help because her dad collapsed. Neighbors came and rushed him to a nearby hospital. It was cardiac arrest and her dad did not survive.',
  'Without a father to care for her anymore, Eleanor now lives with her aunt. She is determined to finish her studies because this is how she can improve her situation. She knows that with a college degree, she will be able to find a stable and good paying job, unlike her parents.',
  'Eleanor’s challenges of getting a good education is not uncommon. There are millions of Eleanors all over the world that have the same fate.',
  'Education is a way out but this is problematic.  Although public primary and secondary schools are usually free, attrition rate is still high because there are costs involved like paying for uniforms, books, projects, and so on. Also, tertiary education in many countries is not free.'
]);

export const TEXT_BLOCK_2 = Object.freeze([
  'Mighty Minds aims to close this gap. The organization believes that a lot of people are actually willing to extend an extra hand to support students especially if there’s an easy and affordable way to do so. Mighty Minds is greatly inspired by Watsi.org and Kiva.org who crowdfund from generous individuals around the world to help humanity. The idea is simple, if many people can contribute even in just small amounts, it will have a significant impact when the funds are put together. Watsi helps people who need medical procedures. Kiva provides capital for livelihood. Mighty Minds will support students finish their education.',
  '100% of the pledged donation goes to the students. Mighty Minds will not get any from this, not a single cent. Currently, Mighty Minds is run by volunteers. Its operational costs are solely funded by the founders.',
  'Mighty Minds envisions a world where everyone has access to good education. Financial constraints should not be a hindrance. No student will be left behind. ',
  'If you believe that education is important and have a bit of time to help out with our mission, we’d like to get to know you more.',
  'Currently, Mighty Minds is in need of the following:'
]);
